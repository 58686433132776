export const menuArray = [
  {
    id: 1,
    title: "產品介紹",
    link: "/#productIntro",
  },
  {
    id: 2,
    title: "常見問題",
    link: "/FAQ",
  },
  {
    id: 3,
    title: "聯繫我們",
    link: "/#contactUs",
  },
];
