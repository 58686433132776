import { getAnswers } from "../commonQuestions/utils";

export const questionsArray = [
  {
    id: "1",
    question: "什麼是精準醫療？",
    answer:
      "精準醫療是準確找到與腫瘤特徵相關的生物標誌物 (biomarker)，透過最新的檢測方法，全面性了解腫瘤的異質性，並根據每個病患自身體質差異量身評估最合適治療策略，也稱作「個人化醫療」。",
    open: false,
  },
  {
    id: "2",
    question: "什麼是癌症基因檢測？",
    answer:
      "檢測癌症細胞的基因。透過次世代基因定序技術(NGS)一次性、準確性、快速性地對癌細胞中產生的基因變異進行掃描，針對這些變異透過癌症資料庫比對與大數據分析，找到對病患最合適的治療方式或藥物選擇。",
    open: false,
  },
  {
    id: "3",
    question: "為什麼要做癌症基因檢測？",
    answer:
      "找到更多的治療機會：相同癌症的病患，可能因為帶有不同突變基因，需要選擇不同的標靶藥物進行治療。不同癌症的病患，也可能因為帶有相同的基因突變，有機會跨癌別找到更多治療選擇。透過癌症基因檢測，可以找出體內癌細胞的突變基因，幫助您找到更多的治療機會。協助癌症的追蹤與監控：癌症的抗藥、復發及轉移是許多癌友最擔心害怕的事，所以有效的追蹤與監控，在每個癌症的階段，都非常的重要，過去多利用腫瘤指標、X光或是電腦斷層掃描來監控癌症的變化。近年的研究發現，透過癌症基因檢測，可以提早發現癌細胞是否抗藥、復發或產生新突變，進一步確認復發的基因突變型態，讓治療適時介入，提高疾病治癒的機會。",
    open: false,
  },
  {
    id: "4",
    question: "癌症基因檢測如何輔助癌症治療？",
    answer:
      "癌症基因檢測能提供完整的癌症突變資訊給醫師，透過醫師的專業評估，為病患規劃更完善的治療方針，協助精準選擇適當藥物達到更佳治療效果，把握癌症的黃金治療期。",
    open: false,
  },
  {
    id: "5",
    question: "我適合做全方位癌症基因檢測嗎？",
    answer:
      "建議您可以先詢問您的主治醫師，針對罹癌確診、治療瓶頸、抗藥或是追蹤復發...等不同的狀況，都須由醫師協助評估，選擇適合的檢測。",
    open: false,
  },
  {
    id: "6",
    question: "什麼癌症適合做全方位癌症基因檢測？",
    answer:
      "全方位癌症基因檢測的方式，依據檢體種類的不同而有別。多元的檢測項目，適用各種類型癌症：各種實體腫瘤：(如肺癌、乳癌、大腸癌、胰臟癌...)皆可採用「安可深」OncoDEEP全方位癌症基因檢測;檢體不足者之患者，則可採用「安可篩」OncoSelect 或「安可追」OncoFellow 液態切片。",
    open: false,
  },
  {
    id: "7",
    question: "OncoDNA癌症基因檢測與傳統癌症基因檢測差異在哪裡？",
    answer:
      "傳統癌症基因檢測鎖定的生物標誌物(biomarker)以基因層次為主，對癌細胞中的DNA基因突變訊息做分析。 「安可深」OncoDEEP癌症基因檢測，結合了次世代基因定序(NGS)與免疫組織化學染色(IHC)兩大檢測方法學，針對腫瘤微環境進行深度剖析，並綜合分析癌細胞的基因變異、轉錄組與其蛋白質表現量，為市售唯一提供DNA、RNA、蛋白質的數據，全方位地解讀病患的腫瘤特徵，提供醫師最全面的資訊做後續的用藥評估。",
    open: false,
  },
  {
    id: "8",
    question: "在什麼時機點做癌症基因檢測最好呢？",
    answer: getAnswers(),
    open: false,
  },
  {
    id: "9",
    question: "檢測不一定有對應用藥，有可能會浪費錢？",
    answer:
      "相同的癌症，可能因帶有不同基因突變，而選擇不同的標靶藥物治療。而不同癌症的病患，也可能因為有相同的基因突變，而有機會跨癌別找到更多治療選擇。一個有品質且經過臨床驗證的檢測，可以提高找到基因突變的正確性與機會，比醫院現有常規檢測更有機會找到對應用藥，且生物醫藥資料時時更新與世界同步，更可以有效開啟新的治療契機。",
    open: false,
  },
  {
    id: "10",
    question: "這個檢測保險會給付嗎？",
    answer:
      "要看您購買的保險公司醫療保險給付內容，可詢問您的保險業務員了解給付的項目。",
    open: false,
  },
  {
    id: "11",
    question: "多久才會拿到報告？",
    answer:
      "醫院病理科切片流程大約需要4-7個日曆天的時間(每家醫院作業時間稍有不同)，檢測約14天，完成送檢後會立即寄送電子報告給您的主治醫師。",
    open: false,
  },
];
